@media (min-width: 1500px) {
  /* Apply a global margin to elements */
  .global-margin {
    margin-left: 200px;
    margin-right: 200px;
  }

  /* Ensure full-width elements ignore global margins */
  .full-width {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /* Background gradient style */
  .bg-wavy-gradient-blue {
    background: radial-gradient(circle at 20% 50%, rgba(0, 180, 254, 0.15), rgba(255, 255, 255, 0) 20%),
                radial-gradient(circle at 70% 50%, rgba(0, 180, 254, 0.1), rgba(255, 255, 255, 0) 15%),
                radial-gradient(circle at 40% 70%, rgba(0, 18, 63, 0.1), rgba(255, 255, 255, 0) 10%);
    background-color: white;
    background-size: 100% 100%;
  }
}
